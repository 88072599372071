html,
body {
  font-family: sans-serif;
  margin: 0;
  height: 100%;
  font-display: swap;
  text-align: justify;
}

mat-drawer {
  margin-left: 5px;
}

[mat-ripple] {
  cursor: pointer;
}

.pl-10 {
  padding-left: 10px;
}

.mb-0 {
  margin-bottom: 0;
}

.vm {
  vertical-align: middle;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.grid-column {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 5px;
}

.nav-caption {
  display: inline-block;
  padding: 0 6px;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--text-primary-color)
}

mat-footer-row.right {
  align-items: end;
  flex-direction: column;
}

.rotate {
  writing-mode: vertical-lr;
}

.rounded {
  border-radius: 5px;
}

.bg-lighter {
  background-color: var(--dark-color-20);
}

.bg-grey {
  background-color: rgba(0, 0, 0, 0.3);
}

.overflow {
  overflow: auto;
}

.noUserSelect {
  user-select: none;
}

.word-break {
  word-break: break-all;
}

.mat-expansion-indicator {
  width: 12px;
}

/*
 *  Icons
 */

.material-symbols {
  font-family: 'Material Symbols Outlined';
}

fa-icon {
  vertical-align: super;
}

/*
 *  menu
 */

.menu .mat-expansion-panel-body {
  padding: 0 5px;
}

.menu .mat-expansion-panel-header .mat-expansion-panel-header-title,
.menu .mat-expansion-panel-header .mat-content {
  margin: 0;
}

/*
 *  app-anchor component
 */

app-anchor>a,
markdown a {
  color: var(--primary-darker-color);
  cursor: pointer;
}

app-anchor.ellipsis>a {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

app-anchor>a:visited,
markdown a:visited {
  color: var(--primary-lighter-color);
}

app-anchor>a:hover,
markdown a:hover {
  color: var(--primary-color);
}

app-anchor mat-icon,
app-anchor fa-icon {
  color: var(--text-accent-color);
}

/*
 *  spaces
*/
.spacer {
  flex: 1 1 auto;
}

.flex-grow {
  flex-grow: 1;
}

h2+p,
h3+p,
p+p {
  margin-top: 0;
}

/*
 *  truncate text
*/
.truncate-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.truncate-left {
  direction: rtl;
  text-align: left;
}

.truncate-left::before {
  content: '\200E';
}

/*
 *  scrollbar
*/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}